@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'MuseoSans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../public/assets/fonts/MuseoSans/MuseoSans-300.woff')
    format('opentype');
}

@font-face {
  font-family: 'MuseoSans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../public/assets/fonts/MuseoSans/MuseoSans-500.woff')
    format('opentype');
}

@font-face {
  font-family: 'MuseoSans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../public/assets/fonts/MuseoSans/MuseoSans-700.woff')
    format('opentype');
}

@font-face {
  font-family: 'MuseoSans';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../public/assets/fonts/MuseoSans/MuseoSans-900.woff')
    format('opentype');
}

@layer base {
  /* -- DESKTOP -- */

  /* Headings */
  .font-display {
    font-size: 48px;
    line-height: 64px;
    font-weight: 900;
  }

  .font-h1,
  h1 {
    font-size: 40px;
    line-height: 56px;
    font-weight: 900;
  }

  .font-h2,
  h2 {
    font-size: 32px;
    line-height: 48px;
    font-weight: 900;
  }

  .font-h3,
  h3 {
    font-size: 28px;
    line-height: 40px;
    font-weight: 900;
  }

  .font-h4,
  h4 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 900;
  }

  .font-h5,
  h5 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }

  /* Paragraphs */
  .font-p1-regular,
  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  .font-p1-medium {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }

  .font-p1-bold {
    font-size: 16px;
    line-height: 24px;
    font-weight: 900;
  }

  .font-p2-regular {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  .font-p2-medium {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  }

  .font-p2-bold {
    font-size: 14px;
    line-height: 20px;
    font-weight: 900;
  }

  .font-p3-regular {
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
  }

  .font-p3-medium {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
  }

  .font-p3-bold {
    font-size: 12px;
    line-height: 16px;
    font-weight: 900;
  }

  /* -- MOBILE -- */
  @media screen and (max-width: 767px) {
    /* Headings */
    .font-display {
      font-size: 40px;
      line-height: 56px;
    }

    .font-h1,
    h1 {
      font-size: 32px;
      line-height: 48px;
    }

    .font-h2,
    h2 {
      font-size: 28px;
      line-height: 40px;
    }

    .font-h3,
    h3 {
      font-size: 24px;
      line-height: 32px;
    }

    .font-h4,
    h4 {
      font-size: 20px;
      line-height: 28px;
    }

    .font-h5,
    h5 {
      font-size: 18px;
      line-height: 24px;
    }

    /* Paragraphs */
    .font-p1-regular,
    p {
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
    }

    .font-p1-medium {
      font-size: 14px;
      line-height: 24px;
      font-weight: 700;
    }

    .font-p1-bold {
      font-size: 14px;
      line-height: 24px;
      font-weight: 900;
    }

    .font-p2-medium {
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
    }
  }

  .customToastifyBodyClassName > div:nth-child(1) {
    margin-right: 0;
  }

  .customToastifyBodyClassName > div:nth-child(2) {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .scrollbarHide::-webkit-scrollbar {
    display: none;
  }

  .scrollbarHide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .mintdashScrollbar {
    position: relative;
    scrollbar-width: thin;
    scrollbar-color: #141414 #e7ecee;
  }

  .mintdashScrollbar::-webkit-scrollbar {
    width: 12px;
  }

  .mintdashScrollbar::-webkit-scrollbar-thumb {
    background-color: #141414;
    background-clip: content-box;
    border-right: 8px solid transparent;
  }

  .mintdashScrollbar::-webkit-scrollbar-track {
    background-color: #e7ecee;
    background-clip: content-box;
    border-right: 8px solid transparent;
    margin-block: 8px;
  }

  .mintdashScrollbar::-webkit-scrollbar-button {
    display: none;
  }

  .mintdashScrollbarModal {
    scrollbar-color: #232323 transparent;
  }

  .mintdashScrollbarModal::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* clears the 'X' from search input in Internet Explorer */
  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the 'X' from search input in Chrome */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  /* hide 'Choose file' button */
  input[type='file']::file-selector-button {
    display: none;
  }

  .dashedBorder {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23333' opacity='0.2' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    background-position: center;
  }

  .filledCircle {
    background: linear-gradient(
        195deg,
        #009595 -205.88%,
        #0a9ce0 119.01%,
        #0a6ce0 391.79%
      ),
      linear-gradient(
        90deg,
        #145dc9 -10.1%,
        #00ad6b 35.42%,
        #eca427 82.8%,
        #dc3843 125.68%
      );
  }
  

  .gradient-bg-blue {
    background: linear-gradient(
        132deg,
        #7bcca5 0%,
        #027976 21.88%,
        #3e7c9d 71.02%,
        #76aadb 100%
      ),
      #2b2b2b;
  }

  .gradient-bg-white {
    background: linear-gradient(
        0deg,
        rgba(10, 160, 224, 0) 0%,
        rgba(6, 125, 125, 0.3) 100%
      ),
      #fff;
  }

  .gradient-bg-base {
    background: linear-gradient(90deg, #00a09a 0%, #00817c 56.84%);
  }

  .active-primary {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.08) 0%,
        rgba(255, 255, 255, 0.08) 100%
      ),
      #141414;
  }

  .active-secondary {
    background: linear-gradient(
        0deg,
        rgba(13, 13, 13, 0.08) 0%,

        rgba(13, 13, 13, 0.08) 100%
      ),
      #f9fafa;
  }

  .endemic-gradient{
    background: linear-gradient(
      180deg,
      #00a09a 0px,
      #d60a51 5000px,
      #f9b233 10000px,
      #d60a51 15000px,
      #00a09a 20000px
    );
  }
  *:focus {
    outline: none;
  }

  * {
    -webkit-tap-highlight-color: transparent;
  }

  .blue-gradient-text {
    background: -webkit-linear-gradient(
      125deg,
      #009595 0%,
      #0a9ce0 79.17%,
      #0a6ce0 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@layer components {
  .numberInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .disableAutofill {
    @apply autofill:!bg-light00 autofill:!shadow-[inset_0_0_0px_1000px_rgb(var(--color-light00))] disabled:autofill:!shadow-[inset_0_0_0px_1000px_rgb(var(--color-light02))] autofill:!text-base autofill:!font-medium;
  }

  .disableAutofill {
    -webkit-text-fill-color: rgb(var(--color-light100));
  }

  .disableAutofill::placeholder {
    -webkit-text-fill-color: rgb(var(--color-light50));
  }

  .disableAutofill:disabled,
  .disableAutofill:disabled::placeholder {
    -webkit-text-fill-color: rgb(var(--color-light30));
  }

  .disableAutofill:invalid,
  .disableAutofill:invalid::placeholder {
    -webkit-text-fill-color: rgb(var(--color-red50));
  }

  .disableAutofillLanding {
    @apply autofill:!bg-landingLight10 autofill:!shadow-[inset_0_0_0px_1000px_#F0F0F0] disabled:autofill:!shadow-[inset_0_0_0px_1000px_rgb(var(--color-landingLight10))] autofill:!text-base autofill:!font-medium;
  }
}

@layer base {
  :root {
    /* #FFF */
    --color-light00: 255, 255, 255;
    /* #F9FAFA */
    --color-light02: 249, 250, 250;
    /* #E7ECEE */
    --color-light05: 231, 236, 238;
    /* #CFD4D6 */
    --color-light10: 207, 212, 214;
    /* #B8BCBD */
    --color-light20: 184, 188, 189;
    /* #A0A4A5 */
    --color-light30: 160, 164, 165;
    /* #898C8D */
    --color-light40: 137, 140, 141;
    /* #717475 */
    --color-light50: 113, 116, 117;
    /* #5A5C5C */
    --color-light60: 90, 92, 92;
    /* #424444 */
    --color-light70: 66, 68, 68;
    /* #353536 */
    --color-light80: 53, 53, 54;
    /* #2B2B2C */
    --color-light90: 43, 43, 44;
    /* #1B1B1D */
    --color-light95: 27, 27, 29;
    /* #131314 */
    --color-light100: 19, 19, 20;

    --color-overlay05: rgba(13, 13, 13, 0.04);
    --color-overlay10: rgba(13, 13, 13, 0.08);
    --color-overlay20: rgba(13, 13, 13, 0.16);
    --color-overlay30: rgba(13, 13, 13, 0.24);
    --color-overlay40: rgba(13, 13, 13, 0.32);
    --color-overlay50: rgba(13, 13, 13, 0.56);
    --color-overlay60: rgba(13, 13, 13, 0.72);
    --color-overlay70: rgba(13, 13, 13, 0.8);
    --color-overlay80: rgba(13, 13, 13, 0.88);
    --color-overlay90: rgba(13, 13, 13, 0.96);

    --color-accentRed: 214, 141, 137;
    --color-icon: #232323;
    --color-iconInvert: #ffffff;
    --color-iconSun: #232323;
    --color-iconMoon: #acacac;
    --color-iconLight50: #acacac;
    --color-iconLight90: #232323;
  }

  [data-theme='dark'] {
    /* #000 */
    --color-light00: 0, 0, 0;
    /* #050606 */
    --color-light02: 20, 20, 20;
    /* #0D0D0D */
    --color-light05: 13, 13, 13;
    /* #141414 */
    --color-light10: 50, 50, 50;
    /* #2B2B2C */
    --color-light20: 43, 43, 44;
    /* #444444 */
    --color-light30: 68, 68, 68;
    /* #5A5C5C */
    --color-light40: 136, 136, 136;
    /* #717475 */
    --color-light50: 113, 116, 117;
    /* #898C8D */
    --color-light60: 137, 140, 141;
    /* #A0A4A5 */
    --color-light70: 160, 164, 165;
    /* #B8BCBD */
    --color-light80: 184, 188, 189;
    /* #CFD4D6 */
    --color-light90: 207, 212, 214;
    /* #DEE5E8 */
    --color-light95: 222, 229, 232;
    /* #E7ECEE */
    --color-light100: 231, 236, 238;

    --color-overlay05: rgba(255, 255, 255, 0.04);
    --color-overlay10: rgba(255, 255, 255, 0.08);
    --color-overlay20: rgba(255, 255, 255, 0.16);
    --color-overlay30: rgba(255, 255, 255, 0.24);
    --color-overlay40: rgba(255, 255, 255, 0.32);
    --color-overlay50: rgba(255, 255, 255, 0.56);
    --color-overlay60: rgba(255, 255, 255, 0.72);
    --color-overlay70: rgba(255, 255, 255, 0.8);
    --color-overlay80: rgba(255, 255, 255, 0.88);
    --color-overlay90: rgba(255, 255, 255, 0.96);

    --color-icon: #ffffff;
    --color-iconInvert: #141414;
    --color-iconSun: #acacac;
    --color-iconMoon: #ffffff;
  }
}

@layer utilities {
  [data-theme='dark'] {
    .mintdashScrollbar {
      scrollbar-color: #f7f7f7 #232323;
    }

    .mintdashScrollbar::-webkit-scrollbar-thumb {
      background-color: #f7f7f7;
    }

    .mintdashScrollbar::-webkit-scrollbar-track {
      background-color: #232323;
    }

    .mintdashScrollbarModal {
      scrollbar-color: #f7f7f7 transparent;
    }

    .mintdashScrollbarModal::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
}
